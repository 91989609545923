/**
 * Created by Terence on 2022/8/23 - 17:39
 * Description :
 */
/* eslint-disable */
import { rpNft, rpCactus } from "@/http";
import { isProd } from "@/utils/env-related";
import axios from "axios";

export default {
  getList() {
    return rpNft.post("/collection/get-trending-collections");
  },
  getTokenDetail(params: any) {
    return rpNft.post("/token/get-token-page", params);
  },
  getPostDetail(params: any) {
    return rpNft.post("/gw/post/get-one", params);
  },
  getSuspectList(params: any) {
    return rpNft.post("/suspect/get-suspects-of-token", params);
  },
  getSuspectListByTopic(params: any) {
    return rpNft.post("/gw/suspect/get-by-topic", params);
  },
  getTokenConfirmStatus(params: any) {
    // return rpNft.post("/token/check-having-processing-order", params);
    return rpNft.post("/gw/order/get-processing-order-of-trade", params);
  },
  getOwnedTokens(ownerId: string) {
    return rpNft.post("/token/get-tokens-by-owner", { ownerId });
  },
  getUserProfile(userId: string) {
    return rpNft.post("/account/get-profile-page", { userId });
  },
  getCreatedTokens(creatorId: string) {
    return rpNft.post("/token/get-tokens-by-creator", { creatorId });
  },

  getSubjectInfo(subjectId: string) {
    return rpNft.post("/subject/get-subject", { subjectId });
  },

  getOcProfile(characterId: string) {
    return rpNft.post("/character/detail", { characterId });
  },

  getOcProfileRelations({
    characterId,
    isBrief,
    limit,
    offset,
  }: {
    characterId: string;
    isBrief: Boolean;
    limit: number;
    offset: number;
  }) {
    return rpNft.post("/character/relation/get-by-character", {
      characterId,
      isBrief,
      limit,
      offset,
    });
  },

  getTokenTypeList(characterId: string) {
    return rpNft.post("/character/token-type-list", { characterId });
  },

  getOcRelatedPost({
    artType,
    characterId,
    limit,
    offset,
  }: {
    artType: string;
    characterId: string;
    limit: number;
    offset: number;
  }) {
    return rpNft.post("/character/related-tokens", {
      artType,
      characterId,
      limit,
      offset,
    });
  },

  getSubjectList(params: any) {
    return rpNft.post("/subject/get-tokens", params);
  },

  getOwnerList(params: any) {
    return rpNft.post("/gw/token/get-by-origin-token", params);
  },

  getTokenTradeInfo(params: any) {
    return rpNft.post("/gw/trade/get-trade-of-token", params);
  },

  // 竞价状态list
  getSubjectMgList(params: any) {
    return rpNft.post("/subject/mg", params);
  },

  getSubjectAuctionList(params: any) {
    return rpNft.post("/trade/auctions", params);
  },

  getSubjectProductList(params: any) {
    return rpNft.post("/trade/sale-orders", params);
  },

  getTokenLogs(params: any) {
    return rpNft.post("/token/get-token-logs", params);
  },

  getBidList(params: any) {
    return rpNft.post("/trade/auction/get-bid-logs", params);
  },

  checkTransStaut(params: any) {
    return rpNft.post("/order/status", params);
  },

  orderConfirm(params: any) {
    return rpNft.post("/order/buyer-confirm", params);
  },

  getFormatedCommentList(
    { characterId, limit, cursor } = { characterId: "", limit: 20, cursor: "" }
  ) {
    return rpNft.post(`/gw/chracter/get-comments`, {
      characterId,
      limit,
      cursor,
    });
  },

  getCommentList(tokenId: string, params: any) {
    return rpCactus.get(`/posts/${tokenId}/comments`, params);
  },

  getCommentListByPostId(params: any) {
    return rpNft.post("/gw/post/get-comments", params);
  },

  getAccountInfo(params: any) {
    return rpNft.post(`/account/info`, params);
  },
  getTypeOfTrade(params: any) {
    return rpNft.post("/gw/trade/get-type-of-trade", params);
  },
  followUser(params: any) {
    return rpNft.post("/follow/follow-user", params);
  },
  unFollowUser(params: any) {
    return rpNft.post("/follow/cancel-follow-user", params);
  },
  getCommonConfig() {
    if (isProd) {
      return axios.get(
        "https://config-cdn.qiandaoapp.com/congress/common-config.json"
      );
    } else {
      return axios.get(
        "https://dev-config-cdn.qiandaoapp.com/congress/common-config.json"
      );
    }
  },
  getSpPublishConfig() {
    if (isProd) {
      return axios.get(
        "https://config-cdn.qiandaoapp.com/congress/commission_config.json"
      );
    } else {
      return axios.get(
        "https://dev-config-cdn.qiandaoapp.com/congress/commission_config.json"
      );
    }
  },
};
