<!--
   Created by Terence on 2024/6/23 - 17:40
-->
<template>
  <div class="publisher">
    <div class="publisher-left" @click="handleToProfile">
      <img
        :src="publisher.avatar + '!mfit_w480_h480_jpg'"
        class="publisher-left-avatar"
      />
      <div class="publisher-left-info">
        <div class="publisher-left-info-name">{{ publisher.nickname }}</div>
        <div
          class="publisher-left-info-auth"
          v-if="publisher.isCertificationCompleted"
        >
          <img
            :src="
              'https://public.qiandaocdn.com/interior/images/d08acddcbcd6e0b3ab027569ab59c563.png' +
              '!mfit_w480_h480_jpg'
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="publisher-right">
      <DuButton
        v-if="publisher.isMyFollowing && publisher.isMyFollower"
        color="secondary"
        type="secondary"
        size="mini"
        @click="handleBtn"
        >互相关注</DuButton
      >
      <DuButton
        v-else-if="publisher.isMyFollowing"
        color="secondary"
        type="secondary"
        size="mini"
        @click="handleBtn"
        >已关注</DuButton
      >
      <DuButton
        v-else-if="publisher.isMyFollower"
        color="secondary"
        type="outline"
        icon="plus"
        size="mini"
        @click="handleBtn"
        >回关</DuButton
      >
      <!--主态看不到关注按钮-->
      <DuButton
        v-else-if="!isPublisher"
        color="secondary"
        type="outline"
        size="mini"
        @click="handleBtn"
        >关注</DuButton
      >
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
// import { DuButton } from "@frontend/dumpling";
import { DuButton } from "dangoui";
import {
  showConfirmDialog,
  showLoadingToast,
  showSuccessToast,
  closeToast,
  showFailToast,
} from "vant";
import ApiNft from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import { invokeAppByYYB } from "@/utils/mob";

const test = ref("");

const publisher = inject("publisher");
const isPublisher = inject("isPublisher");

const emits = defineEmits(["onRefresh"]);

const handleToProfile = () => {
  if (window.isInFlutter) {
    invokeAppByYYB({
      targetPath: `user/${publisher.value?.id}`,
      params: {},
    });
  }
};

const handleBtn = async () => {
  console.log("publisher==", publisher);
  let isFollow = false;

  isFollow = !publisher.value.isFollow;

  let toastPrefix = "关注";
  let tempPromise = undefined;

  if (publisher.value.isMyFollowing) {
    toastPrefix = "取消关注";
    tempPromise = ApiNft.unFollowUser;
  } else {
    toastPrefix = "关注";
    tempPromise = ApiNft.followUser;
  }

  try {
    showLoadingToast();
    const res = await tempPromise({ userId: publisher.value.id });

    if (res.code === REQUEST_OK) {
      showSuccessToast(`${toastPrefix}成功`);
    }
  } catch (e) {
    showFailToast(`${toastPrefix}失败`);
  } finally {
    setTimeout(() => {
      closeToast();
    }, 1000);
    emits("onRefresh");
  }
};
</script>

<style lang="scss" scoped>
.publisher {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &-left {
    display: flex;
    align-items: center;
    &-avatar {
      width: 80rpx;
      height: 80rpx;
      flex-shrink: 0;
      border-radius: 80rpx;
      border: 2rpx solid rgba(32, 36, 38, 0.06);
      display: block;
    }
    &-info {
      margin-left: 8rpx;
      &-name {
        color: #0c0c0c;
        font-size: 28rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 44rpx; /* 157.143% */
      }
      &-auth {
        img {
          display: block;
          height: 40rpx;
        }
      }
    }
  }
}
</style>
