/**
 * Created by Terence on 2024/6/23 - 15:27
 * Description :
 */
import { rpNft, rpApi } from "@/http";

export default {
  getLotteryInfoTest(params: any) {
    return rpApi.post("/c2c-web/v1/c2c-lottery/detail", params);
  },
  joinEvent(params: any) {
    return rpApi.post("/c2c-web/v1/c2c-lottery/participate", params);
  },
  quitEvent(params: any) {
    return rpApi.post("/c2c-web/v1/c2c-lottery/cancel-participate", params);
  },
  deleteEvent(params: any) {
    return rpApi.post("/c2c-web/v1/c2c-lottery/delete", params);
  },
  getWinners(params: any) {
    return rpApi.post("/c2c-web/v1/c2c-lottery/winners", params);
  },
  getAllPlayer(params: any) {
    return rpApi.post("/c2c-web/v1/c2c-lottery/participator", params);
  },
  getLotteryInfo(params: any) {
    return rpApi.post("/c2c-web/v1/c2c-lottery/detail", params);

    return Promise.resolve({
      code: 0,
      // code: "5900001",
      // code: "5900002",
      data: {
        id: "112233",
        title: "测试title1测试title1测试title1",
        description:
          "哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的法\n\n11哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法哈哈哈哈哈哈的放大法",
        prizeCount: "10",
        price: 999,
        drawTime: "1719649852000",
        // participateLimitType: "UNLIMITED", // ONLY_FANS
        participateLimitType: "ONLY_FANS", // ONLY_FANS
        payExpireTime: "1719128084617",
        detailImages: [
          "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
          "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
          "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
        ],
        cover:
          "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
        publisher: {
          id: "568675204956425421", // ytc 小号
          // id: "457052595526044934", // ytc 大号
          nickname: "ytc小号",
          avatar:
            "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
          isMyFollowing: true,
          isMyFollower: true,
          isCertificationCompleted: true,
        },
        prizeName: "奖品名称啊啊啊啊",
        prizeType: "COMMISSION", // 奖品类型
        participateCount: "78", // 总参与人数
        isSatisfiedQualification: true, // 是否满足资格
        isWinner: true,
        orderId: "112233445566",
        state: "DRAWN", // [ ILLEGAL, PROCESSING, DRAWING, DRAWN, ABORT ] // 客态: 只有abort(没有illegal, 只有页面级别的违规)
        illegalReason: "string", // 违规原因
        isParticipated: true, // 是否参与了
        canParticipate: false, // 是否有资格参与
        orderInfo: {
          orderId: "string",
          isPaid: true,
          payExpireTime: "1719128084617",
          state: "INIT", //[ INIT, UNPAID, PAID ]
        },
        participateInfo: {
          list: [
            {
              id: "string",
              nickname: "1111",
              avatar:
                "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
              isFans: true,
              isFollow: true,
              IsCertificationCompleted: true,
            },
            {
              id: "string",
              nickname: "222",
              avatar:
                "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
              isFans: true,
              isFollow: true,
              IsCertificationCompleted: true,
            },
            {
              id: "string",
              nickname: "2233",
              avatar:
                "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
              isFans: true,
              isFollow: true,
              IsCertificationCompleted: true,
            },
            {
              id: "string",
              nickname: "2233",
              avatar:
                "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
              isFans: true,
              isFollow: true,
              IsCertificationCompleted: true,
            },
            {
              id: "string",
              nickname: "2233",
              avatar:
                "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
              isFans: true,
              isFollow: true,
              IsCertificationCompleted: true,
            },
            {
              id: "string",
              nickname: "2233",
              avatar:
                "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
              isFans: true,
              isFollow: true,
              IsCertificationCompleted: true,
            },
            {
              id: "string",
              nickname: "2233",
              avatar:
                "https://public.qiandaocdn.com/interior/images/5202c7f64693adafa7f39510fa1d47e7.png",
              isFans: true,
              isFollow: true,
              IsCertificationCompleted: true,
            },
          ],
          total: "string",
        },
      },
    });
  },
};
