<!--
   Created by Terence on 2024/6/25 - 14:46
-->
<template>
  <span>
    <span v-if="+ctInfo.days">{{ ctInfo.days }}天</span>
    <span
      >{{ ctInfo.hours }}时{{ ctInfo.minutes }}分{{ ctInfo.seconds }}秒</span
    ></span
  >
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { defineProps, defineEmits } from "vue";
import { time } from "@frontend/echo-utils";

const test = ref("");

const props = defineProps({
  expireTime: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["handleRefreshActions"]);

const ctInfo = ref({ days: 0, hours: 0, minutes: 0, seconds: 0 });

const updateCountDown = () => {
  const nowTime = +new Date();
  if (nowTime >= +props.expireTime) {
    emits("handleRefreshActions");
    return;
  }

  const { expireTime } = props;
  const { days, hours, minutes, seconds } = time.getOriginOffset({
    sourceTime: new Date(),
    compareTime: new Date(+expireTime),
  });
  ctInfo.value = { days, hours, minutes, seconds };
};

onMounted(() => {
  updateCountDown();
  const interval = setInterval(updateCountDown, 1000);
  onUnmounted(() => clearInterval(interval));
});
</script>

<style lang="scss" scoped></style>
