<!--
   Created by Terence on 2022/9/8 - 17:47
-->
<template>
  <span :style="style">
    <img
      class="`arrowright`"
      :class="[classNames, `arrowright`, `arrowright--${direction}`].join(' ')"
      :src="calcedType"
      alt=""
    />
  </span>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  classNames: {
    type: String,
  },
  type: {
    type: String,
    default: "default",
  },
  direction: {
    type: String,
    default: "",
  },
  style: {
    type: Object,
  },
});

const calcedType = computed(() => {
  if (props.type === "primary") {
    return "https://cdn.qiandaoapp.com/interior/images/baa79dca0bb7f48d69edc6dbbb34d1d2.png";
  }
  if (props.type === "white") {
    return "https://cdn.qiandaoapp.com/interior/images/d5b5d9e5bc00595063dadaba60ac742a.png";
  } else {
    return "https://cdn.qiandaoapp.com/admins/1ce39fbd06f3fca05725fdc1e2829eca.png";
  }
});
</script>

<style lang="scss" scoped>
.arrowright {
  width: 16rpx;
  height: 16rpx;
}

.arrowright--up {
  transform: rotate(-90deg);
}
.arrowright--down {
  transform: rotate(90deg);
}
.down {
  transform: rotate(90deg);
}
</style>
