/**
 * Created by Terence on 2023/2/15 - 21:15
 * Description :
 */
export const PRE_PAY_POPUP_TYPE = {
  RISK_WARNING: "RISK_WARNING", // 购买风险提示
};

export const SPEND_MONEY_TYPE = {
  JOIN_BID: "JOIN_BID", // 保证金
  PURCHASING: "PURCHASING", // 购买(付款)
  RECHARGE_DEPOSIT: "RECHARGE_DEPOSIT", // 充值保证金
  C2C_LOTTERY_PAY: "C2C_LOTTERY_PAY", // c2c抽选付款
};
