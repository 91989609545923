import hosts from "@/http/hosts";

/**
 *
 * @param orderId
 * @param tokenInfo
 * @param cbUrl 不指定则默认为支付结果页
 * @param cbUrlQuery
 */
export const openPaymentWindow = ({
  orderId,
  tokenInfo,
  description,
  cbUrl,
  cbUrlQuery,
}: {
  orderId: string;
  cbUrl?: string;
  cbUrlQuery?: string;
  description?: string;
  tokenInfo?: {
    tokenId: string;
    coverUrl: string;
    tokenName: string;
  };
}) => {
  const pkgId = 1045;
  const currentCbUrl = cbUrl || window.location.origin + "/pay-result";

  const orderInfo = encodeURIComponent(
    encodeURIComponent(cbUrlQuery + "&orderId=" + orderId)
  );
  const encodedCbUrl = encodeURIComponent(
    currentCbUrl + "?orderInfo=" + orderInfo
  );

  console.log("encodedCbUrl=-=", encodedCbUrl);

  const tempPath = `${
    hosts.paymentH5
  }?orderIds=${orderId}&description=${encodeURIComponent(
    description || ""
  )}&pkg=${pkgId}&cbUrl=${encodedCbUrl}`;

  console.log("tempPath===", tempPath);
  // return;

  window.location.href = tempPath;
};
