<!--
   Created by Terence on 2024/6/23 - 15:24
-->
<template>
  <div class="info">
    <img
      v-if="ltInfo.cover"
      class="info-cover"
      :src="ltInfo.cover + '!lfit_w1080_h1080_png'"
      alt=""
    />
    <div class="info-ct">
      <span class="info-ct-left">
        <span>{{ endTime }}</span>
        <span class="info-ct-left-suffix">开奖</span>
      </span>
      <template v-if="ltInfo.state === LOTTERY_STATE.PROCESSING">
        <span class="info-ct-right" v-if="+ctInfo.days">
          <span class="label"> 距开奖 </span>
          <span class="value"> {{ ctInfo.days }} </span>
          <span class="label"> 天 </span>
          <span class="value"> {{ ctInfo.hours }} </span>
          <span class="label"> 时 </span>
        </span>
        <span class="info-ct-right" v-else>
          <span class="label"> 距开奖 </span>
          <span class="value"> {{ ctInfo.hours }} </span>
          <span class="label"> 时 </span>
          <span class="value"> {{ ctInfo.minutes }} </span>
          <span class="label"> 分 </span>
        </span>
      </template>
      <template v-else-if="ltInfo.state === LOTTERY_STATE.DRAWN">
        <span class="label"> 已开奖 </span>
      </template>
      <template v-else-if="ltInfo.state === LOTTERY_STATE.ABORT">
        <span class="label"> 已终止 </span>
      </template>
    </div>

    <div class="info-main">
      <div class="info-main-price">
        <span>中奖后支付</span>
        <span class="info-main-price-unit">¥</span>
        <span class="info-main-price-value">{{ ltInfo.price }}</span>
        <span class="info-main-price-count">
          本期抽{{ ltInfo.prizeCount }}份
        </span>
      </div>
      <div class="info-main-title otext2">
        {{ ltInfo.prizeName }}
      </div>
      <div class="info-main-reqs">
        <div class="info-main-reqs-item">
          <div class="label">奖品</div>
          <div class="value">
            {{ getPrizeType(ltInfo.prizeType) }}
          </div>
        </div>
        <div class="info-main-reqs-item">
          <div class="label">参与条件</div>
          <div class="value">
            {{ getLimitType(ltInfo.participateLimitType) }}
          </div>
        </div>
        <div class="info-main-reqs-item">
          <div class="label">领奖时间</div>
          <div class="value">
            中奖后需在
            <span :style="{ fontWeight: 500, color: 'black' }">
              {{ getPayEndTime(ltInfo.payExpireTime) }}</span
            >
            前完成支付
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, computed, onUnmounted } from "vue";
import dayjs from "dayjs";
import { useTitle } from "@/hooks/useTitle";
import { time } from "@frontend/echo-utils";
import {
  LIMIT_TYPE_CN,
  LOTTERY_STATE,
  PRIZE_TYPE_CN,
} from "@/constant/lottery";

const { setTitle } = useTitle();

const ltInfo = inject("ltInfo");
const ctInfo = ref({ days: 0, hours: 0, minutes: 0, seconds: 0 });

const endTime = computed(() => {
  return `${dayjs(+ltInfo.value.drawTime).format("YYYY-MM-DD HH:mm")} `;
});

const updateCountDown = () => {
  const finishedTime = ltInfo.value.drawTime;
  const { days, hours, minutes, seconds } = time.getOriginOffset({
    sourceTime: new Date(),
    compareTime: new Date(+finishedTime),
  });
  ctInfo.value = { days, hours, minutes, seconds };
};

const getLimitType = (type) => {
  return LIMIT_TYPE_CN[type];
};

const getPrizeType = (type) => {
  return PRIZE_TYPE_CN[type];
};

const getPayEndTime = (time) => {
  return `${dayjs(+time).format("YYYY-MM-DD HH:mm")} `;
};

onMounted(() => {
  updateCountDown();
  const interval = setInterval(updateCountDown, 1000);
  onUnmounted(() => clearInterval(interval));

  setTitle(ltInfo.value.title);
});

console.log("ltInfo===", ltInfo);
</script>

<style lang="scss" scoped>
.info {
  &-cover {
    width: 750rpx;
    aspect-ratio: 1/1;
    object-fit: cover;
    display: block;
  }
  &-ct {
    padding: 16rpx 30rpx;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f0ff;
    &-left {
      display: flex;
      align-items: center;
      color: #000;
      font-family: "Roboto";
      font-size: 28rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 44rpx; /* 157.143% */
      &-suffix {
        margin-left: 4rpx;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 24rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 36rpx; /* 150% */
      }
    }
    &-right {
      display: flex;
      align-items: center;
      color: white;
      .label {
        color: #000;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 24rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 36rpx; /* 150% */
      }
      .value {
        display: flex;
        align-items: center;
        margin: 0 4rpx;
        display: flex;
        padding: 0 8rpx;
        min-width: 36rpx;
        height: 36rpx;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4rpx;
        background: #000;
        color: #fff;
        font-size: 24rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 36rpx; /* 150% */
      }
    }
  }
  &-main {
    padding: 24rpx 30rpx;
    &-price {
      display: flex;
      align-items: baseline;
      color: #f96464;
      font-family: "PingFang SC";
      font-size: 28rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 44rpx; /* 157.143% */
      &-unit {
        margin: 0 4rpx 0 8rpx;
        color: #f96464;
        font-family: "Roboto";
        font-size: 36rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 50rpx; /* 138.889% */
      }
      &-value {
        color: #f96464;
        font-family: "Roboto";
        font-size: 48rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 64rpx; /* 133.333% */
      }
      &-count {
        margin-left: 8rpx;
        padding: 0 8rpx;
        color: #7c66ff;
        font-size: 24rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 36rpx; /* 150% */
        border-radius: 4rpx;
        border: 2rpx solid #7c66ff;
      }
    }
    &-title {
      margin: 24rpx 0;
      color: #000;
      font-size: 32rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 48rpx; /* 150% */
    }

    &-reqs {
      margin-top: 16rpx;
      &-item {
        display: flex;
        align-items: center;
        margin-top: 16rpx;
        .label {
          color: var(--v-10-main-neural-06, rgba(0, 0, 0, 0.4));
          font-size: 28rpx;
          font-style: normal;
          font-weight: 400;
          line-height: 44rpx; /* 157.143% */
        }
        .value {
          margin-left: 16rpx;
          color: var(---text_secondary, rgba(0, 0, 0, 0.7));
          font-size: 28rpx;
          font-style: normal;
          font-weight: 400;
          line-height: 44rpx; /* 157.143% */
        }
      }
    }
  }
}
</style>
