import qs from "qs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jb from "@frontend/effuse";

export const prepareJump = ({
  atrr,
  targetPath,
  params,
}: {
  atrr: string;
  targetPath: string;
  params: any;
}) => {
  (window as any).MobLink({
    el: atrr,
    path: targetPath,
    default: true,
    loadDataType: "1", //配置是否加loading，配置代表加入loading，不配置则没有loading动画
    params,
  });
};

// https://a.app.qq.com/o/simple.jsp?pkgname=tech.echoing.congress&android_scheme=congress%3A%2F%2Ftoken-detail%3FtokenId%3D548794316743519928&ios_scheme=congress%3A%2F%2Ftoken-detail%3FtokenId%3D548794316743519928

export const invokeAppByYYB = ({
  targetPath,
  params,
}: {
  targetPath: string;
  params: any;
}) => {
  const pkgName = "tech.echoing.congress";
  const tempParams = qs.stringify(params);
  const originTargetUrl = `congress://${targetPath}?${tempParams}`;
  const encodedTargetUrl = encodeURIComponent(originTargetUrl);

  if (window.isInFlutter) {
    jb.navigateTo({ url: originTargetUrl });
    return;
  }

  const resultUrl = `https://a.app.qq.com/o/simple.jsp?pkgname=${pkgName}&android_scheme=${encodedTargetUrl}&ios_scheme=${encodedTargetUrl}`;
  console.log("resultUrl", resultUrl);

  window.location.href = resultUrl;
};

// mobLinkAttr
// prepareJump
