<!--
   Created by Terence on 2024/6/23 - 15:12
-->
<template>
  <div class="lt" v-if="hasData">
    <div class="lt-info">
      <LtInfo />
    </div>
    <div class="lt-publisher">
      <Publisher @onRefresh="handleRefresh" />
    </div>
    <div class="lt-summary">
      <LtSummary @onRefresh="handleRefresh" />
    </div>
    <div class="lt-content">
      <LtContent />
    </div>
    <div class="lt-actions">
      <LtActions ref="ltActionsRef" />
    </div>
  </div>
  <DuEmpty
    extClass="emptypage"
    v-else-if="ltInfoCode === ERROR_TYPES.DELETED"
    text="页面已被删除啦"
  />
  <DuEmpty
    extClass="emptypage"
    image="contentInvisible"
    v-else-if="ltInfoCode === ERROR_TYPES.ILLEGAL"
    text="页面因违规不可见，此抽选已终止，不可再继续参与或开奖"
  />
  <DuEmpty extClass="emptypage" v-if="showDefaultError" text="抽选加载失败" />
</template>

<script setup>
import { ref, onMounted, computed, provide, getCurrentInstance } from "vue";
import { storeToRefs } from "pinia";
import { useUsersStore } from "@/store/user";
import { DuEmpty } from "dangoui";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jb from "@frontend/effuse";
import LtInfo from "./components/LtInfo.vue";
import LtSummary from "./components/LtSummary.vue";
import LtContent from "./components/LtContent.vue";
import LtActions from "./components/LtActions.vue";
import Publisher from "@/components/Publisher/index.vue";
import LotteryApi from "@/apis/lottery";
import { getQuery, updateUrlWithWhitelist } from "@/utils/url";
import { REQUEST_OK } from "@/constant/network";

const inst = getCurrentInstance();

const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

const ERROR_TYPES = {
  DELETED: 5900001,
  ILLEGAL: 5900002,
};

const ltInfo = ref("");
const ltInfoCode = ref(0);
const inited = ref(false);
const ltActionsRef = ref("ltActionsRef");

const isPublisherRef = computed(() => {
  return ltInfo.value?.publisher?.id === userInfo.value?.userId;
});

const hasData = computed(() => {
  return !!ltInfo.value?.id && ltInfoCode.value === REQUEST_OK;
});

const showDefaultError = computed(() => {
  return (
    inited.value &&
    !hasData.value &&
    ltInfoCode.value !== ERROR_TYPES.DELETED &&
    ltInfoCode.value !== ERROR_TYPES.ILLEGAL
  );
});

const ltInfoRef = computed(() => {
  return ltInfo.value;
});

const publisherRef = computed(() => {
  return ltInfo.value.publisher;
});

const initEvent = async () => {
  console.log("--initEvent--!");
  // return;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const { id } = getQuery();
    const res = await LotteryApi.getLotteryInfo({ id });

    inited.value = true;

    console.log("res!--0-0-", res);

    // NftApi.orderConfirm({
    //   orderId: "1122",
    // });
    // LotteryApi.getLotteryInfoTest({ id });

    ltInfo.value = res.data;
    // ltInfo.value.orderInfo.isPaid = false // TODO remove
    // ltInfo.value.orderInfo.payExpireTime = '1719557670000' // TODO remove
    ltInfoCode.value = res.code;

    resolve();
  });
};

const handleRefresh = async () => {
  await initEvent();
  inst?.refs?.ltActionsRef?.initActionConfig();
};

const processQuery = () => {
  const { showWinnerPopup } = getQuery();

  if (showWinnerPopup === "YES") {
    inst?.refs?.ltActionsRef?.handleForceShowWinners();
  }
};

const setShareInfo = () => {
  const shareInfo = {
    title: ltInfo.value.title,
    content: ltInfo.value.description,
    url: updateUrlWithWhitelist(["id"]),
    imageUrl: ltInfo.value.cover + "!mfit_w720_h720_webp",
  };

  jb.shareUrl(shareInfo);
};

onMounted(async () => {
  await initEvent();
  processQuery();
  setShareInfo();
});

provide("ltInfo", ltInfoRef);
provide("publisher", publisherRef);
provide("isPublisher", isPublisherRef);
provide("handleRefreshPage", handleRefresh);
</script>

<style lang="scss" scoped>
.lt {
  background-color: #f7f7f9;
  &-info {
    background-color: #fff;
  }
  &-publisher {
    margin-top: 16rpx;
    padding: 24rpx 30rpx;
    background-color: #fff;
  }
  &-summary {
    margin-top: 16rpx;
    padding: 24rpx 30rpx;
    background-color: #fff;
  }
  &-content {
    margin-top: 16rpx;
    padding: 24rpx 30rpx;
    background-color: #fff;
    padding-bottom: 200rpx;
  }
  &-actions {
    position: fixed;
    //z-index: 100000;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 68rpx;
    background-color: #fff;
  }
}

.emptypage {
  padding: 0 50rpx;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
