<!--
   Created by Terence on 2024/6/23 - 15:24
-->
<template>
  <div class="summary" @click="handleShowWiners">
    <div class="summary-left">参与人数 {{ ltInfo.participateCount }}</div>
    <div class="summary-right">
      <!--      <div class="summary-right-cover" @click="handleShowWiners" />-->
      <div class="summary-right-imgs">
        <img
          :class="`summary-right-imgs-item summary-right-imgs-item-${index}`"
          v-for="(item, index) in ltInfo?.participatorInfo?.items?.splice(0, 6)"
          :src="item.avatar + '!mfit_w480_h480_jpg'"
          alt=""
          :key="index"
          :style="computedStyle(index)"
        />
      </div>
      <ArrowRight />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, computed, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import ArrowRight from "@/components/ArrowRight/index.vue";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";

const router = useRouter();

const ltInfo = inject("ltInfo");

function rpxToRem(rpxValue) {
  const viewportWidth = 750; // 根据视口宽度调整
  const remValue = (rpxValue / viewportWidth) * 10; // 转换公式
  return `${remValue}rem`;
}

const computedStyle = (index) => {
  return {
    right: rpxToRem(index * 380),
  };
};

const handleShowWiners = () => {
  const { id } = getQuery();

  const routerPath = `/c2c-lottery-players?id=${id}&total=${ltInfo.value?.participateCount}`;

  if (window.isInFlutter) {
    invokeAppByYYB({
      targetPath: `hybrid`,
      params: {
        url: window.location.origin + routerPath,
      },
    });
  } else {
    router.push(routerPath);
  }
};

onMounted(() => {
  console.log(1);
});
</script>

<style lang="scss" scoped>
.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-left {
    color: #000;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 44rpx; /* 157.143% */
  }
  &-right {
    position: relative;
    &-cover {
      position: absolute;
      right: 0;
      top: 0;
      width: 250rpx;
      height: 120%;
      //background-color: red;
      z-index: 20;
    }
    &-imgs {
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      &-item {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        width: 48rpx;
        height: 48rpx;
        border-radius: 48rpx;
        border: 2rpx solid #fff;
        z-index: 10;
        object-fit: cover;
      }
    }
  }
}
</style>
