<!--
   Created by Terence on 2024/6/25 - 16:06
-->
<template>
  <DuPopup
    v-model:visible="internalVisible"
    :title="title"
    title-align="center"
    type="bottom"
  >
    <!--    :ext-style="{ height: '50vh', overflowY: 'scroll' }"-->
    <div class="winnerp">
      <div class="winnerp-content">
        <div class="winnerp-content-title">
          中奖名单 ({{ winnerList?.length }}位)
        </div>
        <div class="winnerp-content-card">
          <div class="winnerp-content-card-title">
            奖品: {{ getPrizeType(ltInfo.prizeType) }}
          </div>
          <div class="winnerp-content-card-winners">
            <div
              class="winner-item"
              v-for="(item, index) in winnerList"
              :key="index"
              @click="handleToProfile(item)"
            >
              <div class="winner-item-avatar">
                <img
                  class="winner-item-avatar-content"
                  :src="item.avatar + '!mfit_w480_h480_webp'"
                  alt=""
                />
              </div>
              <div class="winner-item-name otext2">
                {{ item.nickname }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DuPopup>
</template>

<script setup>
import {
  ref,
  watch,
  defineProps,
  defineEmits,
  computed,
  inject,
  onMounted,
} from "vue";
import { DuPopup } from "dangoui";
import { PRIZE_TYPE_CN } from "@/constant/lottery";
import LotteryApi from "@/apis/lottery";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";

const ltInfo = inject("ltInfo");
const isPublisher = inject("isPublisher");

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const internalVisible = ref(props.modelValue);
const winnerList = ref([]);

const handleToProfile = (item) => {
  if (window.isInFlutter) {
    invokeAppByYYB({
      targetPath: `user/${item?.id}`,
      params: {},
    });
  }
};

const getWinnerList = async () => {
  const { id } = getQuery();
  const res = await LotteryApi.getWinners({ lotteryId: id, limit: 100 });

  winnerList.value = res.data.items;
};

watch(
  () => props.modelValue,
  (newVal) => {
    internalVisible.value = newVal;
    if (newVal) {
      getWinnerList();
    }
  }
);

watch(internalVisible, (newVal) => {
  emit("update:modelValue", newVal);
});

const title = computed(() => {
  if (isPublisher.value || !ltInfo.value.isParticipated) {
    return "本次抽选已开奖";
  } else if (ltInfo.value?.isWinner) {
    return "🎉 恭喜你中奖了！";
  } else {
    return "很遗憾，你未中奖";
  }
});

const getPrizeType = (type) => {
  return PRIZE_TYPE_CN[type];
};

onMounted(() => {
  getWinnerList();
});
</script>

<style scoped lang="scss">
.winnerp {
  //padding-bottom: calc(68rpx + 74rpx);
  //height: 60vh;
  padding-bottom: 68rpx;
  &-content {
    padding: 30rpx 32rpx;
    &-title {
      color: rgba(0, 0, 0, 0.4);
      font-size: 28rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 44rpx; /* 157.143% */
    }
    &-card {
      margin-top: 16rpx;
      width: 100%;
      border: 2rpx solid rgba(#000000, 0.08);
      border-radius: 16rpx;
      overflow: hidden;
      &-title {
        padding: 8rpx 0;
        text-align: center;
        background-color: #f7f7f9;
        color: #000;
        font-size: 28rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 44rpx; /* 157.143% */
      }
      &-winners {
        height: 40vh;
        overflow: scroll;
        padding: 24rpx;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20rpx; /* 可根据需要调整间距 */
        .winner-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &-name {
            text-align: center;
            color: #202426;
            text-align: center;
            font-size: 24rpx;
            font-style: normal;
            font-weight: 400;
            height: 72rpx;
            line-height: 36rpx; /* 150% */
          }
          &-avatar {
            display: block;
            width: 80rpx;
            height: 80rpx;
            border-radius: 100%;
            overflow: hidden;
            border: 2rpx solid #a06ee1;
            &-content {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              overflow: hidden;
              border: 4rpx solid #fff;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
