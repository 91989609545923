<!--
   Created by Terence on 2024/6/25 - 16:06
-->
<template>
  <DuPopup v-model:visible="internalVisible" title="参与条件" type="bottom">
    <div class="rqp">
      <div class="rqp-content">
        <div class="rqp-content-item">
          <div class="rqp-content-item-left">
            <div class="title">仅限粉丝</div>
            <div class="subtitle">关注卖家成为粉丝后即可参与</div>
          </div>
          <div class="rqp-content-item-right">
            <DuTheme name="linjie">
              <DuButton @click="handleFollow">关注</DuButton>
            </DuTheme>
          </div>
        </div>
      </div>
    </div>
  </DuPopup>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, computed, inject } from "vue";
import { DuPopup, DuTheme, DuButton } from "dangoui";
import { PRIZE_TYPE_CN } from "@/constant/lottery";
import ApiNft from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import {
  showConfirmDialog,
  showLoadingToast,
  showSuccessToast,
  closeToast,
  showFailToast,
} from "vant";

const ltInfo = inject("ltInfo");
const isPublisher = inject("isPublisher");
const publisher = inject("publisher");
const handleRefreshPage = inject("handleRefreshPage");

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const internalVisible = ref(props.modelValue);

const handleFollow = async () => {
  try {
    showLoadingToast();
    const res = await ApiNft.followUser({ userId: publisher.value.id });

    if (res.code === REQUEST_OK) {
      showSuccessToast(`关注成功`);
    }
  } catch (e) {
    showFailToast(`关注失败`);
  } finally {
    emit("update:modelValue", false);
    setTimeout(() => {
      closeToast();
    }, 1000);
    handleRefreshPage();
  }
};

watch(
  () => props.modelValue,
  (newVal) => {
    internalVisible.value = newVal;
  }
);

watch(internalVisible, (newVal) => {
  emit("update:modelValue", newVal);
});
</script>

<style scoped lang="scss">
.rqp {
  //padding-bottom: calc(68rpx + 74rpx);
  padding-bottom: 68rpx;
  &-content {
    padding: 30rpx 32rpx;
    &-item {
      padding: 24rpx;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8rpx;
      background: #f7f7f9;
      &-left {
        .title {
          color: #000;
          font-size: 28rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 44rpx; /* 157.143% */
        }
        .subtitle {
          color: rgba(0, 0, 0, 0.64);
          font-size: 24rpx;
          font-style: normal;
          font-weight: 400;
          line-height: 36rpx; /* 150% */
        }
      }
    }
  }
}
</style>
