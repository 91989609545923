/**
 * Created by Terence on 2023/2/15 - 21:15
 * Description :
 */
export const REQUEST_OK = 0;

// 挂单(一口价||拍卖) 与后端约定的报错枚举
export const PRODUCT_ERROR_TYPE = {
  ILLEGAL_PRODUCT: "400008",
};

export const PRODUCT_ERROR_REASON = {
  AUCTION: {
    "400008": "商品违规暂不可见\n截拍时卖家未处理则自动流拍",
    "400007": "商品已经被删除",
  },
  BUYOUT: {
    "400008": "商品违规暂不可见",
    "400007": "商品已经被删除",
  },
};
