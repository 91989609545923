<!--
   Created by Terence on 2024/6/23 - 15:24
-->
<template>
  <div class="content">
    <div class="content-title">图文详情</div>
    <div class="content-txt">{{ ltInfo?.description }}</div>
    <div class="content-imgs">
      <img
        v-for="(item, index) in ltInfo?.detailImages"
        :key="index"
        :src="item + '!lfit_w1080_h1080_png'"
        alt=""
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, computed, onUnmounted } from "vue";
import ArrowRight from "@/components/ArrowRight/index.vue";

const ltInfo = inject("ltInfo");

onMounted(() => {
  console.log(1);
});
</script>

<style lang="scss" scoped>
.content {
  &-title {
    margin: 8rpx 0;
    color: #000;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 500;
    line-height: 44rpx; /* 157.143% */
  }
  &-txt {
    white-space: pre-line;
    color: #000;
    font-size: 28rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 44rpx; /* 157.143% */
  }
  &-imgs {
    img {
      margin-top: 16rpx;
      display: block;
      width: 100%;
    }
  }
}
</style>
