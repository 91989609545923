/**
 * Created by Terence on 2023/2/15 - 21:15
 * Description :
 */
export const PRIZE_TYPE = {
  DEFAULT: "DEFAULT",
  COMMISSION: "COMMISSION",
};

export const LIMIT_TYPE = {
  UNLIMITED: "UNLIMITED",
  ONLY_FANS: "ONLY_FANS",
};

export const LIMIT_TYPE_CN = {
  [LIMIT_TYPE.UNLIMITED]: "所有人",
  [LIMIT_TYPE.ONLY_FANS]: "仅限粉丝",
};

export const PRIZE_TYPE_CN = {
  [PRIZE_TYPE.DEFAULT]: "默认",
  [PRIZE_TYPE.COMMISSION]: "稿位",
};

export const LOTTERY_STATE = {
  ILLEGAL: "ILLEGAL",
  PROCESSING: "PROCESSING",
  DRAWING: "DRAWING",
  DRAWN: "DRAWN",
  ABORT: "ABORT",
};

export const LOTTERY_ORDER_STATE = {
  INIT: "INIT",
  UNPAID: "UNPAID",
  PAID: "PAID",
};
